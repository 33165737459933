<template>
  <div
    v-show="isLoading"
    id="loading-block"
  >
    <div
      class="loading-block "
      style="background-color: #f7f7f785;"
    >
      <font-awesome-icon
        class="icone-loading "
        icon="fa-spin fas fa-spinner"
        size="5x"
        style="color: var(--primary);"
      />
      <h4 class="loading_titulo">
        {{ tituloCarregamento }}
      </h4>
      <h4 class="loading_descricao">
        {{ descricao }}
      </h4>
    </div>
    <b-button
      v-if="showCancelButton"
      variant="danger"
      class="button-cancel"
      @click="cancelar()"
    >
      Cancelar
    </b-button>
  </div>
</template>

  <style lang="scss" scoped>
  #loading-block {

      .loading-block {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          height: 100vh;
          z-index: 99999 !important;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

      }
      .loading i {
          position: absolute;
          opacity: 1;
      }

      .loading_titulo {
        position: fixed;
          bottom: 37%;
          z-index: 99999 !important;
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .loading_descricao {
        position: fixed;
          bottom: 33%;
          z-index: 99999 !important;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .button-cancel {
          position: fixed;
          left: 42.5%;
          bottom: 5%;
          z-index: 99999 !important;
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .icone-loading {
          -webkit-animation: rotate 2.5s linear infinite;
          animation: rotate 2.5s linear infinite;
          transition: all 0.3s ease;
          font-size: 4em!important;
          width: 4rem!important;
      }

      @keyframes rotate {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(1turn);
          transform: rotate(1turn);
      }
      }
  }
  </style>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    cancelarUpload: {
      type: Function,
      default: () => {},
    },
    showCancelButton: {
      type: Boolean,
      default: () => true,
    },
    tituloCarregamento: {
      type: String,
      default: () => '',
    },
    descricao: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    cancelar() {
      this.cancelarUpload()
    },
  },
}
</script>
